*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@font-face {
  font-family: "232MKR";
  src: url("232MKRM.c3c38ea6.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "232MKR";
  src: url("232MKRB.46d7c13d.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Baloo;
  src: url("Baloo2-Medium.6f43e7ca.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Baloo;
  src: url("Baloo2-Bold.45251a63.ttf");
  font-weight: 900;
  font-style: normal;
}

.content h1, .content h2 {
  -webkit-hyphens: auto;
  hyphens: auto;
  margin-bottom: .4em;
  font-weight: 400;
}

.content h1 {
  font-size: 250%;
}

.content h2 {
  font-size: 200%;
}

.content p {
  margin-bottom: .8em;
}

.content a {
  color: var(--color-secondary);
  isolation: isolate;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.content a:after {
  content: "";
  height: 2px;
  background: var(--color-secondary);
  z-index: -1;
  width: 0;
  transition: width .5s;
  position: absolute;
  bottom: 1px;
  left: -2px;
  right: -2px;
}

.content a:where(:hover, :focus):after {
  width: calc(100% + 4px);
}

.content ul, .content ol {
  padding-left: 0;
  list-style-position: inside;
}

.content ul p, .content ol p {
  display: inline;
}

.content ul li:has( > p), .content ol li:has( > p) {
  margin-bottom: .8em;
}

.content ul li::marker, .content ol li::marker {
  color: var(--color-secondary);
  font-weight: 900;
}

.content ul li {
  align-items: flex-start;
  list-style: none;
  display: flex;
}

.content ul li:before {
  --point-size: .7rem;
  content: "";
  flex: 0 0 var(--point-size);
  background: var(--color-secondary);
  width: var(--point-size);
  height: var(--point-size);
  border-radius: 50%;
  margin: .5rem 1rem 0 0;
}

.content cite {
  margin-top: .5rem;
  font-style: italic;
  display: block;
}

.content cite:before {
  content: "—";
  margin-right: .2rem;
}

.content blockquote {
  color: var(--color-primary);
  background: #ffffffb3;
  border-radius: .75rem;
  margin-bottom: 2em;
  padding: 2rem;
  position: relative;
}

.content blockquote:before, .content blockquote:after {
  opacity: .1;
  font-size: 20rem;
  line-height: 0;
  position: absolute;
}

.content blockquote:before {
  content: "“";
  top: 8rem;
  left: .5rem;
}

.content blockquote:after {
  content: "„";
  bottom: 10rem;
  right: .5rem;
}

.content blockquote > div {
  z-index: 1;
  position: relative;
}

.content blockquote footer {
  margin-top: .8em;
  font-weight: 700;
}

.content.page {
  color: var(--color-white);
  flex-direction: column;
  flex: 1;
  display: flex;
}

.content.page .section {
  flex: 1;
}

.content.error {
  text-align: center;
}

.header {
  z-index: 10;
  perspective: 1000px;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header .sr-nav {
  background: var(--color-white);
  clip: rect(0, 0, 0, 0);
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.header .sr-nav:focus, .header .sr-nav:focus-within {
  clip: inherit;
}

.header .sr-nav ul {
  justify-content: space-between;
  gap: 1rem;
  display: flex;
}

.header .sr-nav a {
  color: var(--color-primary);
  text-decoration: none;
  display: block;
}

.header-inner {
  background: var(--color-white);
  box-shadow: 0 1px 1px #0000001f, 0 2px 2px #0000001f, 0 4px 4px #0000001f, 0 8px 8px #0000001f, 0 16px 16px #0000001f;
}

.header .container {
  justify-content: space-between;
  align-items: flex-end;
  padding-block: 1rem;
  display: flex;
}

.header button {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--color-primary);
  cursor: pointer;
  box-sizing: content-box;
  background: none;
  border: 0;
  padding: 0;
  position: relative;
}

.header button svg {
  width: 100%;
  height: 100%;
  transition: opacity .5s;
  display: block;
  position: absolute;
  inset: 0;
}

.header button svg.open {
  opacity: 1;
  z-index: 1;
}

.header button svg.close, .header button.is-open .open {
  opacity: 0;
  z-index: -1;
}

.header button.is-open .close {
  opacity: 1;
  z-index: 1;
}

.header img {
  width: 12rem;
  height: auto;
}

.navigation {
  max-height: 0;
  transform-origin: top;
  transition: all .5s;
  transform: rotateX(95deg);
}

@media screen and (max-width: 840px) {
  .navigation {
    transform: rotateX(94deg);
  }
}

.navigation.is-open, .navigation:focus-within {
  max-height: 100vh;
  transform: rotateX(0deg);
}

.navigation-inner {
  background: var(--color-white);
  padding-block: 0 1rem;
  box-shadow: 0 1px 1px #0000001f, 0 2px 2px #0000001f, 0 4px 4px #0000001f, 0 8px 8px #0000001f, 0 16px 16px #0000001f;
}

.navigation a {
  color: var(--color-primary);
  text-decoration: none;
  display: block;
}

.navigation ul {
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media screen and (max-width: 840px) {
  .navigation ul {
    flex-direction: column;
  }

  .navigation ul a {
    padding-block: 1rem;
  }
}

.page-footer {
  background: var(--color-primary);
  z-index: 1;
  padding-block: 2rem;
}

.page-footer ul {
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media screen and (max-width: 620px) {
  .page-footer ul {
    flex-direction: column;
  }
}

.page-footer li {
  padding: 0 1rem;
}

@media screen and (max-width: 620px) {
  .page-footer li {
    margin: .3rem 0;
    padding: 0;
  }
}

.page-footer a {
  color: var(--color-white);
  isolation: isolate;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.page-footer a:after {
  content: "";
  height: 2px;
  background: var(--color-secondary);
  z-index: -1;
  width: 0;
  transition: width .5s;
  position: absolute;
  bottom: 1px;
  left: -2px;
  right: -2px;
}

.page-footer a:where(:hover, :focus):after {
  width: 100%;
}

.page-footer a svg {
  width: 1.5rem;
  height: 1.5rem;
}

.section {
  background: var(--color-primary) url("body_bg.d4bc3c24.png");
  z-index: 1;
  padding-block: 3rem;
  position: relative;
}

.section:first-child {
  padding-top: 12rem;
}

.section .scroll-point {
  scroll-margin-top: 15rem;
}

.spacer {
  height: 80vh;
  z-index: -2;
  isolation: isolate;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 1px 1px #0000001f, inset 0 0 2px 2px #0000001f, inset 0 0 4px 4px #0000001f, inset 0 0 8px 8px #0000001f, inset 0 0 16px 16px #0000001f;
}

@media screen and (max-width: 720px) {
  .spacer {
    height: 70vh;
  }
}

.spacer.is-inViewport {
  z-index: 0;
}

.spacer-image, .spacer-video, .spacer-slider {
  isolation: isolate;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.spacer video, .spacer picture, .spacer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.spacer video {
  object-position: top;
}

.spacer picture, .spacer img {
  object-position: center;
}

.spacer-image {
  justify-content: center;
  align-items: center;
  display: flex;
}

.spacer-image:has(.is-left) {
  justify-content: flex-start;
}

.spacer-image:has(.is-right) {
  justify-content: flex-end;
}

.spacer-image:has(.is-top) {
  align-items: flex-start;
}

.spacer-image:has(.is-bottom) {
  align-items: flex-end;
}

.spacer-image-text {
  max-width: 70%;
  z-index: 1;
  color: #111;
  background: #fffc;
  padding: 1.5rem 2rem;
  position: relative;
  box-shadow: 0 0 4px 4px #0000001f, 0 0 8px 8px #0000001f, 0 0 16px 16px #0000001f;
}

.spacer-image-text.is-left {
  margin-left: 3rem;
}

.spacer-image-text.is-right {
  margin-right: 3rem;
}

.spacer-image-text.is-top {
  margin-top: calc(var(--header-height)  + 3rem);
}

.spacer-image-text.is-bottom {
  margin-bottom: 3rem;
}

@media screen and (max-width: 620px) {
  .spacer-image-text.is-left, .spacer-image-text.is-right, .spacer-image-text.is-center {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.spacer-image-text p {
  margin: 0;
}

.spacer-slider {
  scroll-behavior: smooth;
  flex-wrap: nowrap;
  display: flex;
  overflow: hidden;
}

.spacer-slider-image {
  width: 100%;
  flex: 1 0 100%;
  position: relative;
}

.spacer-slider-control {
  z-index: 3;
  width: 100vw;
  justify-content: center;
  gap: 1rem;
  display: flex;
  position: fixed;
  bottom: 3rem;
}

.spacer-slider-control:not(:has(.is-active)) :first-child {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}

.spacer-slider-control button {
  cursor: pointer;
  width: 1.7rem;
  height: 1.7rem;
  border: 6px solid var(--color-white);
  background: var(--color-white);
  border-radius: 50%;
  box-shadow: 0 0 4px 4px #0000001f, 0 0 8px 8px #0000001f, 0 0 16px 16px #0000001f;
}

.spacer-slider-control button:hover, .spacer-slider-control button:focus {
  border-color: var(--color-secondary);
}

.spacer-slider-control button.is-active {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}

.card {
  --image-width: 30rem;
  --image-height: 45rem;
  --margin-x: 3rem;
  --border-radius: 1.5rem;
  perspective: 100rem;
  position: relative;
}

@media screen and (max-width: 1120px) {
  .card {
    --image-width: 30%;
  }
}

@media screen and (max-width: 960px) {
  .card {
    --image-width: 40%;
  }
}

@media screen and (max-width: 720px) {
  .card {
    --image-width: calc(100% - 2rem);
    --image-height: calc(100vw - 4rem);
    margin-top: 2rem;
  }
}

.card-text {
  color: var(--color-white);
  margin-left: calc(var(--image-width)  - 4rem);
  padding-inline: 7rem 2rem;
}

@media screen and (max-width: 720px) {
  .card-text {
    padding-inline: 2rem;
    padding-top: calc(var(--image-height)  + 3rem);
    margin-left: 0;
  }
}

.card-image {
  z-index: 1;
  top: 0;
  bottom: calc(var(--margin-x) / 2);
  transform-style: preserve-3d;
  perspective: 100rem;
  height: calc(100% - var(--margin-x));
  width: var(--image-width);
  max-height: var(--image-height);
  transition: transform 1.3s;
  position: absolute;
  left: 0;
}

@media screen and (max-width: 720px) {
  .card-image {
    max-height: var(--image-height);
    perspective: 30rem;
    left: 1rem;
  }
}

.card-image img, .card-image picture, .card-image div, .card-image div:before {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
}

.card-image .front, .card-image .back {
  backface-visibility: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
}

.card-image .front img, .card-image .front picture {
  z-index: inherit;
  filter: grayscale();
  position: absolute;
  box-shadow: 0 1px 1px #0000001f, 0 2px 2px #0000001f, 0 4px 4px #0000001f, 0 8px 8px #0000001f, 0 16px 16px #0000001f;
}

.card-image .front div {
  color: var(--color-white);
  isolation: isolate;
  flex-direction: row;
  align-items: center;
  padding: 2rem;
  display: flex;
  position: absolute;
  inset: 0;
}

.card-image .front div:before {
  content: "";
  background: var(--color-secondary);
  opacity: .7;
  z-index: -1;
  position: absolute;
  inset: 0;
}

.card-image .front div.text {
  z-index: 1;
  display: block;
}

.card-image .back, .card.is-inViewport .card-image {
  transform: rotateY(180deg);
}

.card.is-inViewport .card-image:hover, .card.is-inViewport .card-image.touched {
  transform: rotateY(0);
}

.card:is(.section:nth-of-type(2n) .card) .card-text {
  margin-left: 0;
  margin-right: calc(var(--image-width)  - 4rem);
  padding-inline: 2rem 7rem;
}

@media screen and (max-width: 720px) {
  .card:is(.section:nth-of-type(2n) .card) .card-text {
    margin-right: 0;
    padding-inline: 2rem;
    left: 0;
    right: -1rem;
  }
}

.card:is(.section:nth-of-type(2n) .card) .card-image {
  left: unset;
  right: 0;
}

@media screen and (max-width: 720px) {
  .card:is(.section:nth-of-type(2n) .card) .card-image {
    right: 1rem;
  }
}

:root {
  --color-white: #fff;
  --color-primary: #4c7658;
  --color-secondary: #009fe3;
  --font-size: 18px;
  --header-height: 172px;
}

@media screen and (max-width: 960px) {
  :root {
    --font-size: 16px;
    --header-height: 152px;
  }
}

html {
  scroll-behavior: smooth;
  font-size: var(--font-size);
  letter-spacing: .07rem;
  font-family: Baloo, sans-serif;
  font-weight: 300;
}

body {
  min-height: 100vh;
  flex-direction: column;
  display: flex;
}

.container {
  width: min(100% - 2rem, 1200px);
  margin-inline: auto;
}

/*# sourceMappingURL=style.css.map */
