.page-footer
    background: var(--color-primary)
    padding-block: 2rem
    z-index: 1

    ul
        display: flex
        justify-content: flex-end
        list-style: none
        margin: 0
        padding: 0

        @media screen and (max-width: 620px)
            flex-direction: column

    li
        padding: 0 1rem

        @media screen and (max-width: 620px)
            padding: 0
            margin: 0.3rem 0
    a
        color: var(--color-white)
        text-decoration: none
        display: inline-block
        position: relative
        isolation: isolate

        &:after
            content: ""
            height: 2px
            background: var(--color-secondary)
            position: absolute
            z-index: -1
            bottom: 1px
            left: -2px
            right: -2px
            width: 0
            transition: width 500ms

        &:where(:hover, :focus)::after
            width: 100%

        svg
          width: 1.5rem
          height: 1.5rem
