.content
    h1, h2
        font-weight: 400
        margin-bottom: 0.4em
        hyphens: auto

    h1
        font-size: 250%

    h2
        font-size: 200%

    p
        margin-bottom: 0.8em

    a
        text-decoration: none
        color: var(--color-secondary)
        display: inline-block
        position: relative
        isolation: isolate

        &:after
            content: ""
            height: 2px
            background: var(--color-secondary)
            position: absolute
            z-index: -1
            bottom: 1px
            left: -2px
            right: -2px
            width: 0
            transition: width 500ms

        &:where(:hover, :focus)
            &:after
                width: calc(100% + 4px)

    ul, ol
        padding-left: 0
        list-style-position: inside

        p
            display: inline

        li:has(> p)
            margin-bottom: .8em

        li::marker
            color: var(--color-secondary)
            font-weight: 900
    ul li
        list-style: none
        display: flex
        align-items: flex-start
        &::before
            --point-size: .7rem
            content: ""
            flex: 0 0 var(--point-size)
            background: var(--color-secondary)
            border-radius: 50%
            width: var(--point-size)
            height: var(--point-size)
            margin: .5rem  1rem 0 0

    cite
        display: block
        font-style: italic
        margin-top: .5rem

        &:before
            content: "\2014"
            margin-right: .2rem

    blockquote
        background: rgba(255, 255, 255, 0.7)
        color: var(--color-primary)
        padding: 2rem 2rem
        border-radius: 0.75rem
        position: relative
        margin-bottom: 2em

        &:before,
        &:after
            font-size: 20rem
            line-height: 0
            position: absolute
            opacity: .1

        &:before
            content: "\201C"
            top: 8rem
            left: .5rem

        &:after
            content: "\201E"
            bottom: 10rem
            right: .5rem

        > div
            position: relative
            z-index: 1

        footer
            margin-top: 0.8em
            font-weight: 700

    &.page
        flex: 1
        display: flex
        flex-direction: column
        color: var(--color-white)

        .section
            flex: 1

    &.error
        text-align: center
