@use './styles/reset'
@use './styles/fonts'
@use './styles/content'
@use './styles/header'
@use './styles/footer'
@use './styles/section'
@use './styles/spacer'
@use './styles/card'

:root
    --color-white: #fff

    --color-primary: #4c7658
    --color-secondary: #009fe3

    --font-size: 18px
    --header-height: 172px

    @media screen and (max-width: 960px)
        --font-size: 16px
        --header-height: 152px

// *
//     outline: 1px solid rgba(255, 0, 0, .5)

html
    scroll-behavior: smooth
    font-size: var(--font-size)
    font-family: 'Baloo', sans-serif
    font-weight: 300
    letter-spacing: 0.07rem

body
    min-height: 100vh
    display: flex
    flex-direction: column


.container
    width: min(100% - 2rem, 1200px)
    margin-inline: auto
