.section
    background: var(--color-primary) url(./images/body_bg.png)
    padding-block: 3rem
    z-index: 1
    position: relative
    // overflow: hidden

    &:first-child
        padding-top: 12rem

    .scroll-point
        scroll-margin-top: 15rem
