.card
    --image-width: 30rem
    --image-height: 45rem
    --margin-x: 3rem
    --border-radius: 1.5rem

    @media screen and (max-width: 1120px)
        --image-width: 30%
    @media screen and (max-width: 960px)
        --image-width: 40%

    @media screen and (max-width: 720px)
        margin-top: 2rem
        --image-width: calc(100% - 2rem)
        --image-height: calc(100vw - 4rem)


    position: relative
    perspective: 100rem

    &-text
        color: var(--color-white)
        margin-left: calc(var(--image-width) - 4rem)
        padding-inline: 7rem 2rem

        @media screen and (max-width: 720px)
            padding-inline: 2rem 2rem
            margin-left: 0
            padding-top: calc(var(--image-height) + 3rem)


    &-image
        position: absolute
        z-index: 1
        top: 0
        bottom: calc(var(--margin-x) / 2)
        left: 0
        transform-style: preserve-3d
        perspective: 100rem
        transition: transform 1.3s
        height: calc(100% - var(--margin-x))
        width: var(--image-width)
        max-height: var(--image-height)

        @media screen and (max-width: 720px)
            max-height: var(--image-height)
            perspective: 30rem
            left: 1rem

        img, picture, div, div:before
            object-fit: cover
            object-position: center
            width: 100%
            height: 100%
            border-radius: var(--border-radius)

        .front,
        .back
            backface-visibility: hidden
            -webkit-backface-visibility: hidden
            height: 100%
            width: 100%
            position: absolute

        .front
            img, picture
                position: absolute
                z-index: inherit
                filter: grayscale(1)
                box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12)

            div
                position: absolute
                display: flex
                flex-direction: row
                align-items: center
                inset: 0
                color: var(--color-white)
                padding: 2rem
                isolation: isolate

                &::before
                    content: ""
                    position: absolute
                    inset: 0
                    background: var(--color-secondary)
                    opacity: .7
                    z-index: -1

                &.text
                  z-index: 1
                  display: block

        .back
            transform: rotateY(180deg)

    &.is-inViewport .card-image
        transform: rotateY(180deg)
        &:hover, &.touched
            transform: rotateY(0)

    &:is(.section:nth-of-type(even) &)
        .card-text
            margin-left: 0
            margin-right: calc(var(--image-width) - 4rem)
            padding-inline: 2rem 7rem

            @media screen and (max-width: 720px)
                padding-inline: 2rem 2rem
                margin-right: 0
                right: -1rem
                left: 0


        .card-image
            left: unset
            right: 0

            @media screen and (max-width: 720px)
                right: 1rem
