.header
    position: fixed
    top: 0
    left: 0
    right: 0
    z-index: 10
    perspective: 1000px
    height: var(--header-height)

    .sr-nav
      background: var(--color-white)
      position: fixed
      clip: rect(0, 0, 0, 0)
      top: 0
      left: 0
      right: 0
      z-index: 11

      &:focus, &:focus-within
        clip: inherit

      ul
        display: flex
        justify-content: space-between
        gap: 1rem
      a
        display: block
        color: var(--color-primary)
        text-decoration: none

    &-inner
        background: var(--color-white)
        box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12)

    & .container
        display: flex
        justify-content: space-between
        align-items: flex-end
        padding-block: 1rem

    button
        background: transparent
        border: 0
        position: relative
        width: 2.5rem
        height: 2.5rem
        color: var(--color-primary)
        cursor: pointer
        box-sizing: content-box
        padding: 0

        svg
            position: absolute
            inset: 0
            transition: opacity 500ms
            display: block
            width: 100%
            height: 100%

            &.open
                opacity: 1
                z-index: 1

            &.close
                opacity: 0
                z-index: -1

        &.is-open
            .open
                opacity: 0
                z-index: -1

            .close
                opacity: 1
                z-index: 1



    img
        width: 12rem
        height: auto

.navigation
    max-height: 0
    transform-origin: top
    transform: rotateX(95deg)
    transition: all 500ms

    @media screen and (max-width: 840px)
        transform: rotateX(94deg)


    &.is-open, &:focus-within
        max-height: 100vh
        transform: rotateX(0deg)
    &-inner
        background: var(--color-white)
        box-shadow: 0 1px 1px rgba(0,0,0,0.12), 0 2px 2px rgba(0,0,0,0.12), 0 4px 4px rgba(0,0,0,0.12), 0 8px 8px rgba(0,0,0,0.12), 0 16px 16px rgba(0,0,0,0.12)
        padding-block: 0 1rem
    a
        display: block
        color: var(--color-primary)
        text-decoration: none

    ul
        flex: 1
        display: flex
        align-items: flex-end
        justify-content: space-between
        list-style: none
        margin: 0
        padding: 0

        @media screen and (max-width: 840px)
            flex-direction: column

            a
                padding-block: 1rem
