.spacer
  height: 80vh
  overflow: hidden
  position: relative
  z-index: -2
  isolation: isolate
  box-shadow: inset 0 0 1px 1px rgba(0,0,0,0.12), inset 0 0 2px 2px rgba(0,0,0,0.12), inset 0 0 4px 4px rgba(0,0,0,0.12), inset 0 0 8px 8px rgba(0,0,0,0.12), inset 0 0 16px 16px rgba(0,0,0,0.12)

  @media screen and (max-width: 720px)
    height: 70vh

  &.is-inViewport
    z-index: 0

  &-image,
  &-video,
  &-slider
    position: fixed
    isolation: isolate
    inset: 0
    width: 100%
    height: 100%

  video,
  picture,
  img
    position: absolute
    width: 100%
    height: 100%
    object-fit: cover

  video
    object-position: top
  picture,
  img
    object-position: center

  &-image
    display: flex
    justify-content: center
    align-items: center
    &:has(.is-left)
      justify-content: flex-start
    &:has(.is-right)
      justify-content: flex-end
    &:has(.is-top)
      align-items: flex-start
    &:has(.is-bottom)
      align-items: flex-end

    &-text
      position: relative
      max-width: 70%
      z-index: 1
      background: rgba(255 255 255 / 80%)
      color: #111
      padding: 1.5rem 2rem
      box-shadow: 0 0 4px 4px rgba(0,0,0,0.12), 0 0 8px 8px rgba(0,0,0,0.12), 0 0 16px 16px rgba(0,0,0,0.12)

      &.is-left
        margin-left: 3rem
      &.is-right
        margin-right: 3rem
      &.is-top
        margin-top: calc( var(--header-height) + 3rem  )
      &.is-bottom
        margin-bottom: 3rem

      @media screen and (max-width: 620px)
        &.is-left,
        &.is-right,
        &.is-center
          margin-left: 2rem
          margin-right: 2rem

      p
        margin: 0

  &-slider
    display: flex
    flex-wrap: nowrap
    overflow: hidden
    scroll-behavior: smooth
    &-image
      flex: 1 0 100%
      width: 100%
      position: relative
    &-control
      position: fixed
      z-index: 3
      bottom: 3rem
      width: 100vw
      display: flex
      justify-content: center
      gap: 1rem

      &:not(:has(.is-active))
        :first-child
          background: var(--color-secondary)
          border-color: var(--color-secondary)

      button
        cursor: pointer
        width: 1.7rem
        height: 1.7rem
        border-radius: 50%
        box-shadow: 0 0 4px 4px rgba(0,0,0,0.12), 0 0 8px 8px rgba(0,0,0,0.12), 0 0 16px 16px rgba(0,0,0,0.12)
        border: 6px solid var(--color-white)
        background: var(--color-white)

        &:hover, &:focus
          border-color: var(--color-secondary)

        &.is-active
          background: var(--color-secondary)
          border-color: var(--color-secondary)
