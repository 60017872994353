@font-face
    font-family: "232MKR"
    src: url("./fonts/232MKRM.ttf")
    font-weight: normal
    font-style: normal

@font-face
    font-family: "232MKR"
    src: url("./fonts/232MKRB.ttf")
    font-weight: 900
    font-style: normal

@font-face
    font-family: "Baloo"
    src: url("./fonts/Baloo2-Medium.ttf")
    font-weight: normal
    font-style: normal

@font-face
    font-family: "Baloo"
    src: url("./fonts/Baloo2-Bold.ttf")
    font-weight: 900
    font-style: normal